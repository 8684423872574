
import { defineComponent, reactive } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import CModal from "@/components/Modal.vue";

const VAgendaConfirmDeletion = defineComponent({
  name: "VAgendaConfirmDeletion",
  components: { CModal },
  setup() {
    useHead({ title: "Confirmar exclusão da agenda | obmed" });

    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const _id = Number(route.params._id);
    const loading = reactive({ confirm: false });

    function handleClose() {
      router.back();
    }

    async function deleteAgenda() {
      loading.confirm = true;
      const response = await store.dispatch("deleteAgenda", { _id });
      loading.confirm = false;

      if (response?.status === 200) handleClose();
    }

    return { loading, deleteAgenda, handleClose };
  },
});

export default VAgendaConfirmDeletion;
